<template>
  <div class="page-wrapper">
    <b-container fluid>
      <bo-page-title>
        <div class="ml-2">
          <router-link class="btn m-l-15 router-link-exact-active router-link-active btn-success" to="add-article"><i
              class="fas fa-plus mr-2"></i>
            Add New
          </router-link>
        </div>
      </bo-page-title>
      <b-tabs>
        <b-tab title="All Posts" id="article" active>
          <b-card-header>
            <b-row>
              <b-col lg="12">
                <div class="wrap_sts_total">
                  <ul>
                    <li><a href="">All <span>(557)</span></a> </li>
                    <li><a href="">Published <span>(515)</span></a> </li>
                    <li><a href="">Schedule <span>(557)</span></a> </li>
                    <li><a href="">Draft <span>(557)</span></a> </li>
                  </ul>
                </div>
              </b-col>
            </b-row>
          </b-card-header>
          <b-card-header>
            <b-row>
              <b-col md="auto">
                <b-button-group>
                  <b-button v-b-tooltip.hover title="Delete"><i class="ti-trash"></i></b-button>
                </b-button-group>
              </b-col>
              <b-col md="auto">
                <b-button-group>
                  <b-button @click="viewMode = 'list'" v-b-tooltip.hover title="List Mode"
                    :variant="viewMode == 'list' ? 'success' : ''">
                    <i class="fas fa-list"></i>
                  </b-button>
                  <b-button @click="viewMode = 'grid'" v-b-tooltip.hover title="Grid Mode"
                    :variant="viewMode == 'grid' ? 'success' : ''">
                    <i class="fas fa-th"></i>
                  </b-button>
                </b-button-group>
              </b-col>
              <b-col md="auto">
                <v-date-picker v-model="date">
                  <template v-slot="{ inputValue, inputEvents }">
                    <div class="flex items-center">
                      <input class="bg-white custom_inp px-2 py-1 rounded" :value="inputValue" v-on="inputEvents" />
                    </div>
                  </template>
                </v-date-picker>
              </b-col>
              <b-col md="3">
                <b-form-group class="mb-0">
                  <v-select placeholer="Select Category" v-model="selected_category" :options="category" />
                </b-form-group>
              </b-col>
              <b-col md="auto">
                <bo-search />
              </b-col>
            </b-row>
          </b-card-header>
          <bo-card use-table v-if="viewMode == 'list'">
            <div class="table-responsive">
              <table class="table table-hover table-striped table-bordered">
                <thead>
                  <tr>
                    <th scope="col">
                      <div class="custom-control custom-checkbox mr-sm-2">
                        <input type="checkbox" class="custom-control-input" id="checkbox0" value="check">
                        <label class="custom-control-label" for="checkbox0">
                        </label>
                      </div>
                    </th>
                    <th scope="col">Title</th>
                    <th scope="col">Categories</th>
                    <th scope="col">Status</th>
                    <th scope="col">Statistic</th>
                    <th scope="col">Actions</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th>
                      <div class="custom-control custom-checkbox mr-sm-2">
                        <input type="checkbox" class="custom-control-input" id="checkbox0" value="check">
                        <label class="custom-control-label" for="checkbox0">
                        </label>
                      </div>
                    </th>
                    <td>
                      <div class="wrap_title_post">
                        <h3><a href="">Kemenparekraf Lirik Kota Malang, Kembangkan Pariwisata Era Society 5.0</a>
                        </h3>
                        <ul>
                          <li><i class="icon-user"></i> Administrator</li>
                          <li><i class=" ti-calendar"></i> 2021/11/08 at 9:00 am</li>
                        </ul>
                      </div>
                    </td>
                    <td>Accommodation</td>
                    <td>
                      <span class="badge badge-success">Published</span><br>
                    </td>
                    <td>
                      <div class="wrap_title_post">
                        <ul>
                          <li><i class="icon-eye"></i> 152</li>

                          <li><a href=""><i class="icon-bubbles"></i> 22</a></li>
                        </ul>
                      </div>
                    </td>
                    <td>
                      <a href="#" class="icon_action"><i class="ti-marker-alt"></i></a>
                      <a href="#" class="icon_action"><i class="ti-trash"></i></a>
                      <a href="#" class="icon_action"><i class="ti-eye"></i></a>
                    </td>
                  </tr>
                  <tr>
                    <th>
                      <div class="custom-control custom-checkbox mr-sm-2">
                        <input type="checkbox" class="custom-control-input" id="checkbox0" value="check">
                        <label class="custom-control-label" for="checkbox0">
                        </label>
                      </div>
                    </th>
                    <td>
                      <div class="wrap_title_post">
                        <h3><a href="">Tampilan Anyar Candi Borobudur, Bentuk Pariwisata yang Sustainable?</a></h3>
                        <ul>
                          <li><i class="icon-user"></i> Administrator</li>
                          <li><i class=" ti-calendar"></i> 2021/11/08 at 9:00 am</li>
                        </ul>
                      </div>
                    </td>
                    <td>Accommodation</td>
                    <td>
                      <span class="badge badge-success">Published</span><br>
                    </td>
                    <td>
                      <div class="wrap_title_post">
                        <ul>
                          <li><i class="icon-eye"></i> 152</li>

                          <li><a href=""><i class="icon-bubbles"></i> 22</a></li>
                        </ul>
                      </div>
                    </td>
                    <td>
                      <a href="#" class="icon_action"><i class="ti-marker-alt"></i></a>
                      <a href="#" class="icon_action"><i class="ti-trash"></i></a>
                      <a href="#" class="icon_action"><i class="ti-eye"></i></a>
                    </td>
                  </tr>
                  <tr>
                    <th>
                      <div class="custom-control custom-checkbox mr-sm-2">
                        <input type="checkbox" class="custom-control-input" id="checkbox0" value="check">
                        <label class="custom-control-label" for="checkbox0">
                        </label>
                      </div>
                    </th>
                    <td>
                      <div class="wrap_title_post">
                        <h3><a href="">Pariwisata Labuan Bajo Kembali Mengeliat</a></h3>
                        <ul>
                          <li><i class="icon-user"></i> Administrator</li>
                          <li><i class=" ti-calendar"></i> 2021/11/08 at 9:00 am</li>
                        </ul>
                      </div>
                    </td>
                    <td>Accommodation</td>
                    <td>
                      <span class="badge badge-success">Published</span><br>
                    </td>
                    <td>
                      <div class="wrap_title_post">
                        <ul>
                          <li><i class="icon-eye"></i> 152</li>

                          <li><a href=""><i class="icon-bubbles"></i> 22</a></li>
                        </ul>
                      </div>
                    </td>
                    <td>
                      <a href="#" class="icon_action"><i class="ti-marker-alt"></i></a>
                      <a href="#" class="icon_action"><i class="ti-trash"></i></a>
                      <a href="#" class="icon_action"><i class="ti-eye"></i></a>
                    </td>
                  </tr>
                  <tr>
                    <th>
                      <div class="custom-control custom-checkbox mr-sm-2">
                        <input type="checkbox" class="custom-control-input" id="checkbox0" value="check">
                        <label class="custom-control-label" for="checkbox0">
                        </label>
                      </div>
                    </th>
                    <td>
                      <div class="wrap_title_post">
                        <h3><a href="">Pesona Cappadocia, Sebuah 'Dunia Lain' di Turki yang Wajib Dikunjungi</a></h3>
                        <ul>
                          <li><i class="icon-user"></i> Administrator</li>
                          <li><i class=" ti-calendar"></i> 2021/11/08 at 9:00 am</li>
                        </ul>
                      </div>
                    </td>
                    <td>Xperience</td>
                    <td>
                      <span class="badge badge-success">Published</span><br>
                    </td>
                    <td>
                      <div class="wrap_title_post">
                        <ul>
                          <li><i class="icon-eye"></i> 152</li>

                          <li><a href=""><i class="icon-bubbles"></i> 22</a></li>
                        </ul>
                      </div>
                    </td>
                    <td>
                      <a href="#" class="icon_action"><i class="ti-marker-alt"></i></a>
                      <a href="#" class="icon_action"><i class="ti-trash"></i></a>
                      <a href="#" class="icon_action"><i class="ti-eye"></i></a>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <template #footer>
              <b-pagination class="mb-0" v-model="currentPage" :total-rows="rows" :per-page="perPage" prev-text="Prev"
                next-text="Next" />
            </template>
          </bo-card>
          <bo-card v-else>
            <b-row cols="1" cols-md="2" cols-lg="4" class="gutter-2">
              <b-col>
                <div class="post-block">
                  <div class="post-block-media">
                    <b-img blank width="380" height="253" fluid-grow blank-color="#333" src=""
                      class="post-block__img" />
                    <b-badge variant="success" class="post-block__badge">Published</b-badge>
                    <div class="post-block-actions">
                      <b-button class="btn-icon" size="sm" variant="danger">
                        <i class="fas fa-trash-alt"></i>
                      </b-button>
                      <b-button class="btn-icon" size="sm" variant="warning">
                        <i class="fas fa-pencil-alt"></i>
                      </b-button>
                    </div>
                  </div>
                  <div class="post-block-body">
                    <span class="post-block__cat">Accommodation</span>
                    <h4 class="font-weight-bold post-block__title">Kemenparekraf Lirik Kota Malang, Kembangkan Pariwisata Era Society 5.0
                    </h4>
                    <span class="post-block__meta">
                      <i class="icon-user"></i> Administrator
                    </span>
                    <span class="post-block__meta">
                      <i class="ti-calendar"></i> {{ '2021/02/07 18:37' | moment('lll') }}
                    </span>
                  </div>
                  <div class="post-block-statistics">
                    <span class="post-block-statistics__item">
                      <i class="icon-eye"></i> 152
                    </span>
                    <span class="post-block-statistics__item">
                      <i class="icon-bubbles"></i> 22
                    </span>
                  </div>
                </div>
              </b-col>
              <b-col>
                <div class="post-block">
                  <div class="post-block-media">
                    <b-img blank width="380" height="253" fluid-grow blank-color="#333" src=""
                      class="post-block__img" />
                    <b-badge variant="success" class="post-block__badge">Published</b-badge>
                    <div class="post-block-actions">
                      <b-button class="btn-icon" size="sm" variant="danger">
                        <i class="fas fa-trash-alt"></i>
                      </b-button>
                      <b-button class="btn-icon" size="sm" variant="warning">
                        <i class="fas fa-pencil-alt"></i>
                      </b-button>
                    </div>
                  </div>
                  <div class="post-block-body">
                    <span class="post-block__cat">Accommodation</span>
                    <h4 class="font-weight-bold post-block__title">Kemenparekraf Lirik Kota Malang, Kembangkan Pariwisata Era Society 5.0</h4>
                    <span class="post-block__meta">
                      <i class="icon-user"></i> Administrator
                    </span>
                    <span class="post-block__meta">
                      <i class="ti-calendar"></i> {{ '2021/02/07 18:37' | moment('lll') }}
                    </span>
                  </div>
                  <div class="post-block-statistics">
                    <span class="post-block-statistics__item">
                      <i class="icon-eye"></i> 152
                    </span>
                    <span class="post-block-statistics__item">
                      <i class="icon-bubbles"></i> 22
                    </span>
                  </div>
                </div>
              </b-col>
              <b-col>
                <div class="post-block">
                  <div class="post-block-media">
                    <b-img blank width="380" height="253" fluid-grow blank-color="#333" src=""
                      class="post-block__img" />
                    <b-badge variant="success" class="post-block__badge">Published</b-badge>
                    <div class="post-block-actions">
                      <b-button class="btn-icon" size="sm" variant="danger">
                        <i class="fas fa-trash-alt"></i>
                      </b-button>
                      <b-button class="btn-icon" size="sm" variant="warning">
                        <i class="fas fa-pencil-alt"></i>
                      </b-button>
                    </div>
                  </div>
                  <div class="post-block-body">
                    <span class="post-block__cat">Accommodation</span>
                    <h4 class="font-weight-bold post-block__title">Pariwisata Labuan Bajo Kembali Mengeliat</h4>
                    <span class="post-block__meta">
                      <i class="icon-user"></i> Administrator
                    </span>
                    <span class="post-block__meta">
                      <i class="ti-calendar"></i> {{ '2021/02/07 18:37' | moment('lll') }}
                    </span>
                  </div>
                  <div class="post-block-statistics">
                    <span class="post-block-statistics__item">
                      <i class="icon-eye"></i> 152
                    </span>
                    <span class="post-block-statistics__item">
                      <i class="icon-bubbles"></i> 22
                    </span>
                  </div>
                </div>
              </b-col>
              <b-col>
                <div class="post-block">
                  <div class="post-block-media">
                    <b-img blank width="380" height="253" fluid-grow blank-color="#333" src=""
                      class="post-block__img" />
                    <b-badge variant="success" class="post-block__badge">Published</b-badge>
                    <div class="post-block-actions">
                      <b-button class="btn-icon" size="sm" variant="danger">
                        <i class="fas fa-trash-alt"></i>
                      </b-button>
                      <b-button class="btn-icon" size="sm" variant="warning">
                        <i class="fas fa-pencil-alt"></i>
                      </b-button>
                    </div>
                  </div>
                  <div class="post-block-body">
                    <span class="post-block__cat">Xperience</span>
                    <h4 class="font-weight-bold post-block__title">Pesona Cappadocia, Sebuah 'Dunia Lain' di Turki yang Wajib Dikunjungi
                    </h4>
                    <span class="post-block__meta">
                      <i class="icon-user"></i> Administrator
                    </span>
                    <span class="post-block__meta">
                      <i class="ti-calendar"></i> {{ '2021/02/07 18:37' | moment('lll') }}
                    </span>
                  </div>
                  <div class="post-block-statistics">
                    <span class="post-block-statistics__item">
                      <i class="icon-eye"></i> 152
                    </span>
                    <span class="post-block-statistics__item">
                      <i class="icon-bubbles"></i> 22
                    </span>
                  </div>
                </div>
              </b-col>
             
            </b-row>
          </bo-card>
        </b-tab>
        <b-tab title="SEO Settings">
          <b-card no-body>
            <b-form @submit.prevent="submitMethod">
              <b-card-body>
                <b-form-group label-for="homeMetaTitle">
                  <template #label>
                    Meta Title
                    <b-badge variant="info" pill class="help-badge"
                      v-b-tooltip.hover.right="'This field functions to ease Google understand the title of this website page. Pro tip: Make sure the characters length is less than 70 chars.'">
                      <i class="fas fa-question"></i>
                    </b-badge>
                  </template>
                  <b-form-input id="homeMetaTitle" placeholder="Meta Title" v-model="seoRow.title" />
                </b-form-group>
                <b-form-group label-for="homeMetaDesc">
                  <template #label>
                    Meta Description
                    <b-badge variant="info" pill class="help-badge"
                      v-b-tooltip.hover.right="'This field functions to ease Google understand the title of this website page. Pro tip: Make sure the characters length is less than 70 chars.'">
                      <i class="fas fa-question"></i>
                    </b-badge>
                  </template>
                  <b-form-textarea id="homeMetaDesc" placeholder="Meta Description" v-model="seoRow.description" />
                </b-form-group>
                <b-form-group label-for="homeMetaTags">
                  <template #label>
                    Meta Tags
                    <b-badge variant="info" pill class="help-badge"
                      v-b-tooltip.hover.right="'This field functions to ease Google understand the title of this website page. Pro tip: Make sure the characters length is less than 70 chars.'">
                      <i class="fas fa-question"></i>
                    </b-badge>
                  </template>
                  <b-form-tags id="homeMetaTags" placeholder="Type and press enter ..." remove-on-delete
                    tag-variant="success" tag-class="text-white" v-model="seoRow.keywords" />
                </b-form-group>
              </b-card-body>
              <b-card-footer>
                <div class="text-right">
                  <b-button type="submit" variant="success">Submit</b-button>
                </div>
              </b-card-footer>
            </b-form>
          </b-card>
        </b-tab>
      
      </b-tabs>
    </b-container>
  </div>
</template>
<script>
  import GlobalVue from '@/libs/Global.vue'
  export default {
    name: 'Article',
    extends: GlobalVue,
    components: {},

    data() {
      return {
        selected: 'A',
        ads_device: [{
            item: 'A',
            name: 'Desktop'
          },
          {
            item: 'B',
            name: 'Mobile'
          }
        ],
        date: new Date(),
        days: [],
        featured: 'not_accepted',
        seoRow: {},
        articleRow: {},
        articleData: [],
        rows: 30,
        perPage: 1,
        currentPage: 5,
        selected_category: 'All Category',
        category: [
          'All Category',
          'Accommodation',
          'Xperience',
          'Flight'
        ],
        selected_sort: 'Sort By',
        sort: [
          'Sort By',
          'Most Viewed',
          'Least Viewed',
          'Oldest',
          'Newest',
        ],
        selected_ads_size: 'Select Ads Size',
        ads_size: [
          'Select Ads Size',
          '300x250',
          '336x280',
          '728x90',
          '300x600',
          '320x100',
          '200x50',
          '320x20',
        ],
        viewMode: 'list'
      }
    },
    methods: {
      logData() {
        console.log('Button Pressed!')
      }
    },
  }
</script>